module.exports = function(gantt) {

	gantt.config.touch_drag = 500; //nearly immediate dnd
	gantt.config.touch = true;
	gantt.config.touch_feedback = true;
	gantt.config.touch_feedback_duration = 1;
	gantt._prevent_touch_scroll = false;


	gantt._touch_feedback = function () {
		if (gantt.config.touch_feedback) {
			if (navigator.vibrate)
				navigator.vibrate(gantt.config.touch_feedback_duration);
		}
	};

	gantt.attachEvent("onGanttReady", gantt.bind(function(){
		if (this.config.touch != "force")
			this.config.touch = this.config.touch &&
				((navigator.userAgent.indexOf("Mobile") != -1) ||
					(navigator.userAgent.indexOf("iPad") != -1) ||
					(navigator.userAgent.indexOf("Android") != -1) ||
					(navigator.userAgent.indexOf("Touch") != -1));

		if (this.config.touch) {

			var touchEventsSupported = true;
			try {
				document.createEvent("TouchEvent");
			} catch (e) {
				touchEventsSupported = false;
			}

			if (touchEventsSupported) {
				this._touch_events(["touchmove", "touchstart", "touchend"], function (ev) {
					if (ev.touches && ev.touches.length > 1) return null;
					if (ev.touches[0])
						return {
							target: ev.target,
							pageX: ev.touches[0].pageX,
							pageY: ev.touches[0].pageY,
							clientX: ev.touches[0].clientX,
							clientY: ev.touches[0].clientY
						};
					else
						return ev;
				}, function () {
					return false;
				});
			} else if (window.navigator.pointerEnabled) {
				this._touch_events(["pointermove", "pointerdown", "pointerup"], function (ev) {
					if (ev.pointerType == "mouse") return null;
					return ev;
				}, function (ev) {
					return (!ev || (ev.pointerType == "mouse" ));
				});
			} else if (window.navigator.msPointerEnabled) {
				this._touch_events(["MSPointerMove", "MSPointerDown", "MSPointerUp"], function (ev) {
					if (ev.pointerType == ev.MSPOINTER_TYPE_MOUSE) return null;
					return ev;
				}, function (ev) {
					return (!ev || ev.pointerType == ev.MSPOINTER_TYPE_MOUSE);
				});
			}

		}
	}, gantt));


	function getTaskDND(){
		var _tasks_dnd;
		if(gantt.$ui.getView("timeline")){
			_tasks_dnd = gantt.$ui.getView("timeline")._tasks_dnd;
		}
		return _tasks_dnd;
	}

	var touchHandlers = [];

//we can't use native scrolling, as we need to sync momentum between different parts
//so we will block native scroll and use the custom one
//in future we can add custom momentum
	gantt._touch_events = function (names, accessor, ignore) {
		//webkit on android need to be handled separately
		var dblclicktime = 0;
		var action_mode = false;
		var scroll_mode = false;
		var action_start = null;
		var scroll_state;
		var long_tap_timer = null;
		var current_target = null;



		for(var i = 0; i < touchHandlers.length; i++){
			gantt.eventRemove(touchHandlers[i][0], touchHandlers[i][1], touchHandlers[i][2]);
		}
		touchHandlers = [];

		//touch move
		touchHandlers.push([gantt.$container, names[0], function (e) {
			var _tasks_dnd = getTaskDND();

				if (ignore(e)) return;

				//ignore common and scrolling moves
				if (!action_mode) return;

				if (long_tap_timer) clearTimeout(long_tap_timer);

				var source = accessor(e);
				if (_tasks_dnd && (_tasks_dnd.drag.id || _tasks_dnd.drag.start_drag)) {
					_tasks_dnd.on_mouse_move(source);
					if (e.preventDefault)
						e.preventDefault();
					e.cancelBubble = true;
					return false;
				}
				if (!gantt._prevent_touch_scroll) {
					if (source && action_start) {
						var dx = action_start.pageX - source.pageX;
						var dy = action_start.pageY - source.pageY;
						if (!scroll_mode && (Math.abs(dx) > 5 || Math.abs(dy) > 5)) {
							gantt._touch_scroll_active = scroll_mode = true;
							dblclicktime = 0;
							scroll_state = gantt.getScrollState();
						}

						if (scroll_mode) {
							gantt.scrollTo(scroll_state.x + dx, scroll_state.y + dy);
							var new_scroll_state = gantt.getScrollState();

							if ((scroll_state.x != new_scroll_state.x && dy > 2 * dx) ||
								(scroll_state.y != new_scroll_state.y && dx > 2 * dy )) {
								return block_action(e);
							}
						}
					}
					return block_action(e);
				}
				return true;
			}]);


		//block touch context menu in IE10
		touchHandlers.push([this.$container, "contextmenu", function (e) {
			if (action_mode)
				return block_action(e);
		}]);

		//touch start
		touchHandlers.push([this.$container, names[1], function (e) {
			if (ignore(e)) return;
			if (e.touches && e.touches.length > 1) {
				action_mode = false;
				return;
			}

			action_start = accessor(e);
			if (!gantt._locate_css(action_start, "gantt_hor_scroll") && !gantt._locate_css(action_start, "gantt_ver_scroll")) {
				action_mode = true;
			}
			var _tasks_dnd = getTaskDND();

			//long tap
			long_tap_timer = setTimeout(function () {
				var taskId = gantt.locate(action_start);
				if (_tasks_dnd && (taskId && !gantt._locate_css(action_start, "gantt_link_control") && !gantt._locate_css(action_start, "gantt_grid_data"))) {
					_tasks_dnd.on_mouse_down(action_start);

					if (_tasks_dnd.drag && _tasks_dnd.drag.start_drag) {
						cloneTaskRendered(taskId);
						_tasks_dnd._start_dnd(action_start);
						gantt._touch_drag = true;

						gantt.refreshTask(taskId);

						gantt._touch_feedback();
					}

				}

				long_tap_timer = null;
			}, gantt.config.touch_drag);
		}]);

		//touch end
		touchHandlers.push([this.$container, names[2], function (e) {
			if (ignore(e)) return;
			if (long_tap_timer) clearTimeout(long_tap_timer);
			gantt._touch_drag = false;
			action_mode = false;
			var source = accessor(e);

			var _tasks_dnd = getTaskDND();

			if(_tasks_dnd)
				_tasks_dnd.on_mouse_up(source);

			if (current_target) {
				gantt.refreshTask(gantt.locate(current_target));
				if (current_target.parentNode) {
					current_target.parentNode.removeChild(current_target);
					gantt._touch_feedback();
				}
			}

			gantt._touch_scroll_active = action_mode = scroll_mode = false;
			current_target = null;

			//dbl-tap handling
			if (action_start && dblclicktime) {
				var now = new Date();
				if ((now - dblclicktime) < 500) {

					var mouseEvents = gantt.$services.getService("mouseEvents");
					mouseEvents.onDoubleClick(action_start);
					block_action(e);
				} else
					dblclicktime = now;
			} else {
				dblclicktime = new Date();
			}
		}]);

		for(var i = 0; i < touchHandlers.length; i++){
			gantt.event(touchHandlers[i][0], touchHandlers[i][1], touchHandlers[i][2]);
		}

		//common helper, prevents event
		function block_action(e) {
			if (e && e.preventDefault)
				e.preventDefault();
			(e || event).cancelBubble = true;
			return false;
		}

		function cloneTaskRendered(taskId) {
			var renders = gantt._getTaskLayers();
			var task = gantt.getTask(taskId);
			if (task && gantt.isTaskVisible(taskId)) {
				for (var i = 0; i < renders.length; i++) {
					task = renders[i].rendered[taskId];
					if (task && task.getAttribute(gantt.config.task_attribute) && task.getAttribute(gantt.config.task_attribute) == taskId) {
						var copy = task.cloneNode(true);
						current_target = task;
						renders[i].rendered[taskId] = copy;
						task.style.display = "none";
						copy.className += " gantt_drag_move ";
						task.parentNode.appendChild(copy);
						//return copy;
					}
				}
			}
		}
	};

};