module.exports = function(gantt){
	if(!gantt.ext){
		gantt.ext = {};
	}

	var modules = [
		require("./autoscroll"),
		require("./batch_update"),
		require("./wbs"),
		require("./jquery_hooks"),
		require("./dhtmlx_hooks"),
		require("./resources"),
		require("./new_task_placeholder"),
		require("./auto_task_types"),
		require("./formatters")
	];

	for(var i = 0; i < modules.length; i++){
		if(modules[i])
			modules[i](gantt);
	}

	var TimelineZoom = require("./timeline_zoom").default;
	gantt.ext.zoom = new TimelineZoom(gantt);
};