function extend(gantt){

	gantt.destructor = function(){
		gantt.callEvent("onDestroy", []);
		this.clearAll();

		if(this.$root){
			delete this.$root.gantt;
		}

		this._eventRemoveAll();
		if(this.$layout){
			this.$layout.destructor();
		}

		this.resetLightbox();

		if(this._dp && this._dp.destructor){
			this._dp.destructor();
		}
		this.$services.destructor();

		// detachAllEvents should be called last, because in components may be attached events
		this.detachAllEvents();

		for(var i in this){
			if(i.indexOf("$") === 0){
				delete this[i];
			}
		}
		gantt.$destroyed = true;
	};
}

module.exports = extend;
