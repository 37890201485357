/*

 TRANSLATION BY MATTHEUS PIROVANI RORIZ GONЗALVES

 mattheusroriz@hotmail.com / mattheus.pirovani@gmail.com /

 www.atrixian.com.br

 */

gantt.locale = {
	date: {
		month_full: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
		month_short: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
		day_full: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
		day_short: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
	},
	labels: {
		new_task: "Nova tarefa",
		dhx_cal_today_button: "Hoje",
		day_tab: "Dia",
		week_tab: "Semana",
		month_tab: "Mês",
		new_event: "Novo evento",
		icon_save: "Salvar",
		icon_cancel: "Cancelar",
		icon_details: "Detalhes",
		icon_edit: "Editar",
		icon_delete: "Deletar",
		confirm_closing: "Suas alterações serão perdidas. Você tem certeza?", //Your changes will be lost, are your sure ?
		confirm_deleting: "Tem certeza que deseja excluir?",
		section_description: "Descrição",
		section_time: "Período de tempo",
		section_type: "Type",
		/* grid columns */

		column_wbs: "EAP",
		column_text: "Nome tarefa",
		column_start_date: "Data início",
		column_duration: "Duração",
		column_add: "",

		/* link confirmation */
		link: "Link",
		confirm_link_deleting: "será apagado",
		link_start: " (início)",
		link_end: " (fim)",

		type_task: "Task",
		type_project: "Project",
		type_milestone: "Milestone",


		minutes: "Minutos",
		hours: "Horas",
		days: "Dias",
		weeks: "Semanas",
		months: "Meses",
		years: "Anos",

		/* message popup */
		message_ok: "OK",
		message_cancel: "Cancelar",

		/* constraints */
		section_constraint: "Constraint",
		constraint_type: "Constraint type",
		constraint_date: "Constraint date",
		asap: "As Soon As Possible",
		alap: "As Late As Possible",
		snet: "Start No Earlier Than",
		snlt: "Start No Later Than",
		fnet: "Finish No Earlier Than",
		fnlt: "Finish No Later Than",
		mso: "Must Start On",
		mfo: "Must Finish On",

		/* resource control */
		resources_filter_placeholder: "type to filter",
		resources_filter_label: "hide empty"
	}
};