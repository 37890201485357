module.exports = function(gantt) {

	var BaseEditor = require("./base")(gantt),
		utils = require("../../../../../utils/utils");
	var __extends = require("../../../../../utils/extends");

	function TextEditor() {
		var self = BaseEditor.apply(this, arguments) || this;
		return self;
	}

	__extends(TextEditor, BaseEditor);

	utils.mixin(TextEditor.prototype, {
		show: function (id, column, config, placeholder) {
			var html = "<div><input type='text' name='" + column.name + "'></div>";
			placeholder.innerHTML = html;
		}
	}, true);

	return TextEditor;
};