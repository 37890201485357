module.exports = function getVisibleTasksRange(gantt, view, datastore, viewport){
	var config = view.$getConfig();
	var height = config.row_height;
	var buffer = 5;
	var indexStart = Math.max(0, Math.floor(viewport.y / height) - buffer);
	var indexEnd =  Math.min(datastore.count(), Math.ceil(viewport.y_end / height) + buffer);
	return {
		start: indexStart,
		end: indexEnd
	};
};