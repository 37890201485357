var utils = require("../../utils/utils");
var createArgumentsHelper = require("./calendar_arguments_helper");
var CalendarWorktimeStrategy = require("./strategy/calendar_strategy");

function CalendarManager (gantt){
	this.$gantt = gantt;
	this._calendars = {};
}

CalendarManager.prototype = {
	_calendars: {},
	_getDayHoursForMultiple: function (calendars, date) {
		var units = [],
			tick = true,
			currPos = 0,
			is_work_hour = false,
			start = this.$gantt.date.day_start(new Date(date));
		for (var hour = 0; hour < 24; hour++) {
			is_work_hour = calendars.reduce(function (acc, calendar) {
				return acc && calendar._is_work_hour(start);
			}, true);
			if (is_work_hour) {
				if (tick) {
					units[currPos] = hour;
					units[currPos + 1] = (hour + 1);
					currPos += 2;
				} else {
					units[currPos - 1] += 1;
				}
				tick = false;
			} else if (!tick) {
				tick = true;
			}
			start = this.$gantt.date.add(start, 1, "hour");
		}
		if (!units.length)
			units = false;
		return units;
	},
	mergeCalendars: function () {
		var newCalendar = this.createCalendar(),
			day,
			units = [];
		var calendars = Array.prototype.slice.call(arguments, 0);
		newCalendar.worktime.hours = [0, 24];
		newCalendar.worktime.dates = {};
		var start = this.$gantt.date.day_start(new Date(259200000)); // 1970 day=0
		for (day = 0; day < 7; day++) {
			units = this._getDayHoursForMultiple(calendars, start);
			newCalendar.worktime.dates[day] = units;
			start = this.$gantt.date.add(start, 1, "day");
		}
		for (var i = 0; i < calendars.length; i++) {
			for (var value in calendars[i].worktime.dates) if (+value > 10000) {
				units = this._getDayHoursForMultiple(calendars, new Date(+value));
				newCalendar.worktime.dates[value] = units;
			}
		}
		return newCalendar;
	},

	_convertWorktimeSettings: function (settings) {
		var days = settings.days;
		if (days) {
			settings.dates = settings.dates || {};
			for (var i = 0; i < days.length; i++) {
				settings.dates[i] = days[i];
				if (!(days[i] instanceof Array)) {
					settings.dates[i] = !!days[i];
				}
			}
			delete settings.days;
		}
		return settings;
	},

	createCalendar: function (parentCalendar) {
		var settings;

		if (!parentCalendar) {
			parentCalendar = {};
		}

		if (parentCalendar.worktime) {
			settings = utils.copy(parentCalendar.worktime);
		} else {
			settings = utils.copy(parentCalendar);
		}

		var defaults = utils.copy(this.defaults.fulltime.worktime);
		utils.mixin(settings, defaults);

		var id = utils.uid();
		var calendar = {
			id: id + "",
			worktime: this._convertWorktimeSettings(settings)
		};

		var apiCore = new CalendarWorktimeStrategy(this.$gantt, createArgumentsHelper(this.$gantt));
		utils.mixin(apiCore, calendar);

		// validate/check if empty calendar
		if (!apiCore._tryChangeCalendarSettings(function () {
			})) {
			return null;
		} else {
			return apiCore;
		}
	},

	getCalendar: function (id) {
		id = id || "global";
		this.createDefaultCalendars();
		return this._calendars[id];
	},

	getCalendars: function () {
		var res = [];
		for (var i in this._calendars) {
			res.push(this.getCalendar(i));
		}
		return res;
	},

	_getOwnCalendar: function(task){
		var config = this.$gantt.config;
		if (task[config.calendar_property]) {
			return this.getCalendar(task[config.calendar_property]);
		}

		if (config.resource_calendars) {
			for (var field in config.resource_calendars) {
				var resource = config.resource_calendars[field];
				if (task[field]) {
					var calendarId = resource[task[field]];
					if (calendarId) {
						return this.getCalendar(calendarId);
					}
				}
			}
		}
		return null;
	},

	getTaskCalendar: function (task) {
		if (!task) {
			return this.getCalendar();
		}

		var calendar = this._getOwnCalendar(task);
		var gantt = this.$gantt;
		if (!calendar && gantt.config.inherit_calendar && gantt.isTaskExists(task.parent)){
			var stop = false;
			gantt.eachParent(function(parent){
				if(stop) return;
				if(gantt.isSummaryTask(parent)){
					calendar = this._getOwnCalendar(parent);
					if(calendar){
						stop = true;
					}
				}
			}, task.id, this);
		}

		return calendar || this.getCalendar();
	},

	addCalendar: function (calendar) { // puts new calendar to Global Storage - gantt.calendarManager._calendars {}
		if (!(calendar instanceof CalendarWorktimeStrategy)) {
			var id = calendar.id;
			calendar = this.createCalendar(calendar);
			calendar.id = id;
		}
		var config = this.$gantt.config;

		calendar.id = calendar.id || utils.uid();
		this._calendars[calendar.id] = calendar;
		if (!config.worktimes)
			config.worktimes = {};
		config.worktimes[calendar.id] = calendar.worktime;
		return calendar.id;
	},

	deleteCalendar: function (calendar) {
		var config = this.$gantt.config;
		if (!calendar) return false;
		if (this._calendars[calendar]) {
			delete this._calendars[calendar];
			if (config.worktimes && config.worktimes[calendar])
				delete config.worktimes[calendar];
			return true;
		} else {
			return false;
		}	},

	restoreConfigCalendars: function (configs) {
		for (var i in configs) {
			if (this._calendars[i])
				continue;

			var settings = configs[i];
			var calendar = this.createCalendar(settings);
			calendar.id = i;
			this.addCalendar(calendar);
		}
	},

	defaults: {
		global: {
			id: "global",
			worktime: {
				hours: [8, 17],
				days: [0, 1, 1, 1, 1, 1, 0]
			}
		},
		fulltime: {
			id: "fulltime",
			worktime: {
				hours: [0, 24],
				days: [1, 1, 1, 1, 1, 1, 1]
			}
		}
	},

	createDefaultCalendars: function () {
		var config = this.$gantt.config;
		this.restoreConfigCalendars(this.defaults);
		this.restoreConfigCalendars(config.worktimes);
	}
};

module.exports = CalendarManager;