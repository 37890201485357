function createMixin(){
	var topCache = {};
	return {
		_resetTopPositionHeight: function(){
			topCache = {};
		},

		/**
		 * Get top coordinate by row index (order)
		 * @param {number} index
		 */
		getRowTop: function(index){
			return index * this.$getConfig().row_height;
		},

		/**
		 * Get top coordinate by item id
		 * @param {*} task_id
		 */
		getItemTop: function (taskId) {
			if(this.$config.rowStore){
				if(topCache[taskId] !== undefined){
					return topCache[taskId];
				}
				var store = this.$config.rowStore;
				if(!store) return 0;

				var itemIndex = store.getIndexById(taskId);

				if (itemIndex === -1 && store.getParent && store.exists(taskId)) {
					var parentId = store.getParent(taskId);
					if (store.exists(parentId)) {
						// if task is not found in list - maybe it's parent is a split task and we should use parents index instead
						var parent = store.getItem(parentId);
						if (this.$gantt.isSplitTask(parent)) {
							return this.getRowTop(store.getIndexById(parent.id));
						}
					}
				}
				topCache[taskId] = this.getRowTop(itemIndex);
				return topCache[taskId];
			}else{
				return 0;
			}

		}
	};
}

module.exports = createMixin;