import { IWorkUnitCache } from "./workunit_cache_interface";

export class WorkUnitsObjectCache implements IWorkUnitCache {
	private _cache: any;
	constructor() {
		this.clear();
	}

	getItem(unit: string, timestamp: string): number|boolean {
		const cache = this._cache;
		if (cache && cache[unit]) {
			const units = cache[unit];

			if (units[timestamp] !== undefined) {
				return units[timestamp];
			}
		}

		return -1;
	}
	setItem(unit: string, timestamp: string, value: boolean): void {
		if (!unit || !timestamp) {
			return;
		}

		const cache = this._cache;

		if (!cache) {
			return;
		}
		if (!cache[unit]) {
			cache[unit] = {};
		}
		cache[unit][timestamp] = value;
	}
	clear(): void{
		this._cache = {};
	}
}